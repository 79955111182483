@charset "UTF-8";
/*
トップ
*/
/*
slickスライダー
*/
@import url(../../../js/page/service/top/slick.css);
.slick-slide {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  outline: 0; }
  .slick-slide:not(.slick-center) {
    position: relative;
    overflow: hidden; }
    .slick-slide:not(.slick-center):after {
      content: "";
      background: rgba(0, 0, 0, 0.22);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .slick-slide img {
    width: 100%;
    height: auto; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: calc(-63px / 2);
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 1; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: fontawesome;
  font-size: 50px;
  line-height: 63px;
  width: 63px;
  height: 63px;
  display: inline-block;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #6a7278; }

.slick-prev {
  left: 11.3%; }

[dir='rtl'] .slick-prev {
  right: 11.3%;
  left: auto; }

.slick-prev:before {
  content: "\f104"; }

[dir='rtl'] .slick-prev:before {
  content: "\f105"; }

.slick-next {
  right: 11.3%; }

[dir='rtl'] .slick-next {
  right: auto;
  left: 11.3%; }

.slick-next:before {
  content: "\f105";
  margin-left: -44px; }

[dir='rtl'] .slick-next:before {
  content: "\f104"; }

:not([class*="bg"]) > section {
  padding-top: 0; }

.mainSlide.slick-slider + * {
  margin-top: 0; }

.kit6 {
  margin-bottom: 0 !important; }

.kitOrder > *:nth-child(n+1) {
  margin-top: 20px; }
  @media screen and (max-width: 640px) {
    .kitOrder > *:nth-child(n+1) {
      margin-top: 0; } }

.kitOrder a {
  background: #fff;
  display: block; }
  .kitOrder a:hover {
    animation: flash .3s; }

.kitOrderDetail {
  padding: 0 20px 20px;
  border-right: 1px dashed lightgray; }
  @media screen and (max-width: 800px) {
    .kitOrderDetail {
      border-left: 1px dashed lightgray; } }

#main .bgDna {
  background: url(/inc/image/service/top/bg_dna.jpg) right 0 no-repeat;
  margin: -50px auto;
  padding: 60px 0;
  letter-spacing: -.02em; }
  @media screen and (max-width: 640px) {
    #main .bgDna {
      background: none;
      padding: 30px 16px; } }

#main .bgFlow {
  background: url(/inc/image/service/top/bg_flow.jpg) 0 0 no-repeat;
  margin: 0 auto;
  padding: 60px 0;
  letter-spacing: -.02em; }
  @media screen and (max-width: 640px) {
    #main .bgFlow {
      padding: 30px 16px;
      position: relative;
      overflow: hidden; } }
  @media screen and (max-width: 640px) {
    #main .bgFlow:before {
      position: absolute;
      z-index: 1;
      content: "";
      background: #fff;
      opacity: .6;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    #main .bgFlow > * {
      position: relative;
      z-index: 2; } }

.toOnlineShop:not(.mfp-bg) {
  margin-top: 0; }
